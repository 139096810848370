.modal-flesh-message {
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    line-height: 2.4rem;
    font-size: 1.6rem;
    text-align: center;

    .image-wrapper {
      margin-bottom: 0.4rem;
    }

    .title {
      font-weight: 700;
      margin-bottom: 0.8rem;
    }

    .content-wrapper {
      margin-bottom: 1.6rem;
    }

    .button {
      width: 100%;
    }

    .spinner-loader {
      width: 4rem;
      height: 4rem;
    }

    .spinner {
      width: 3.6rem;
      height: 3.6rem;
    }
  }
}

.modal-flesh-message.fail .ant-modal-body .title {
  color: #d65555;
}

.modal-flesh-message.success .ant-modal-body .title {
  color: #1d9f55;
}

.modal-flesh-message.loading .ant-modal-body .title {
  color: #404040;
  font-weight: normal;
}
